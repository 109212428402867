@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: scroll;
}

.MuiAutocomplete-popper {
    -ms-overflow-style: none !important;  /* Internet Explorer 10+ */
    scrollbar-width: none !important;  /* Firefox */
}

.MuiAutocomplete-popper > * > ::-webkit-scrollbar {
    display: none;
}

.ReactModal__Overlay {
    z-index: 1000;
}